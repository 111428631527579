<template>
  <div class="col-12 col-lg-8 c g">
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_table/create')">
                <h3>
                    <img :src="require('@/assets/images/table-create.png')" alt="">
                    إنشاء الجدول
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_table/history')">
                <h3>
                    <img :src="require('@/assets/images/save.png')" alt="">
                    الجداول المحفوظة
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_table/settings')">
                <h3>
                    <img :src="require('@/assets/images/settings.png')" alt="">
                    الإعدادات
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_table/teachers')">
                <h3>
                    <img :src="require('@/assets/images/manage-users.png')" alt="">
                    ادارة المعلمين
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_table/classrooms')">
                <h3>
                    <img :src="require('@/assets/images/classroom.png')" alt="">
                    ادارة الفصول
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_table/subjects')">
                <h3>
                    <img :src="require('@/assets/images/subjec.png')" alt="">
                    ادارة المواد
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_table/waiting')">
                <h3>
                    <img :src="require('@/assets/images/waiting.png')" alt="">
                    حصص الانتظار
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_table/realtime')">
                <h3>
                    <img :src="require('@/assets/images/monitor.png')" alt="">
                    الشاشة المباشرة
                </h3>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
        }
    }
}
</script>

<style>
.item{
    border: solid 2px #fff;
}
.item:hover{
    border: solid 2px #ddd;
    cursor: pointer;
    background: #fafafa;
}
.item img{
    width: 64px;
}
</style>